import { z } from 'zod';

import {
  isCaptcha,
  notEmptyDate,
  notEmptyNumber,
  notEmptySlug,
  notEmptyString,
  optionalNumber,
  optionalString,
} from './utils';
import { validatePostalCodeRange } from './validate-postalcode-range';
import { validateTierOfPricingDaysExists } from './validate-tier-of-pricing-days-exists';
import { validateUserExists } from './validate-user-exists';
import { validateUpdateUserEmail } from './validate-update-user-email';

const DAY_IN_MS = 24 * 60 * 60 * 1000;
export const DELIVERY_DATE_PAST12_MESSAGE =
  'Om de bestelling de volgende dag te laten bezorgen, moet deze vóór 12:00 uur geplaatst worden.';
export const EMAIL_TAKEN_MESSAGE = 'Dit e-mailadres is al in gebruik.';

export const linkFormSchema = z.object({
  pathname: optionalString.transform((pathname) => {
    return pathname || '/';
  }),
  name: notEmptyString,
  links: z
    .array(
      z.object({
        pathname: optionalString.transform((pathname) => {
          return pathname || '/';
        }),
        name: notEmptyString,
      })
    )
    .optional(),
});
export type LinkForm = z.infer<typeof linkFormSchema>;

export const headerFormSchema = z.object({
  underMenuItems: z.array(linkFormSchema),
  upperMenuItems: z.array(linkFormSchema),
  banner: optionalString,
});

export const navigationMenuFormSchema = z.object({
  name: notEmptyString,
  pathname: optionalString,
  links: z.array(linkFormSchema),
});
export type NavigationMenuForm = z.infer<typeof navigationMenuFormSchema>;

export const footerFormSchema = z.object({
  navigationMenus: z.array(navigationMenuFormSchema),
});

export const SettingsFormSchema = z.object({
  reviewRating: notEmptyNumber,
  dishwashCost: notEmptyNumber,
  mondayOpen: notEmptyString,
  mondayClose: notEmptyString,
  tuesdayOpen: notEmptyString,
  tuesdayClose: notEmptyString,
  wednesdayOpen: notEmptyString,
  wednesdayClose: notEmptyString,
  thursdayOpen: notEmptyString,
  thursdayClose: notEmptyString,
  fridayOpen: notEmptyString,
  fridayClose: notEmptyString,
  saturdayOpen: notEmptyString,
  saturdayClose: notEmptyString,
  sundayOpen: notEmptyString,
  sundayClose: notEmptyString,
});
export type SettingsForm = z.infer<typeof SettingsFormSchema>;

export const webhookEventSchema = z.object({
  resource_id: z.number(),
  resource_type: z.string(),
  event_type: z.union([z.literal('create'), z.literal('update'), z.literal('delete')]),
  event_datetime: z.string(),
});

export const loginFormSchema = z.object({
  password: z
    .string({
      errorMap: () => ({ message: 'Dit veld mag niet leeg zijn.' }),
    })
    .min(1, { message: 'Dit veld mag niet leeg zijn.' }),
  validatedCaptcha: isCaptcha,
  email: z
    .string({
      errorMap: () => ({ message: 'Dit veld mag niet leeg zijn.' }),
    })
    .min(1, { message: 'Dit veld mag niet leeg zijn.' })
    .email(),
});
export type LoginForm = z.infer<typeof loginFormSchema>;

const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\W_])[A-Za-z\d\W_]{8,}$/;

export const registerFormSchema = z
  .object({
    redirect: z.boolean().optional(),
    callbackUrl: optionalString,
    validatedCaptcha: isCaptcha,
    password: z
      .string({
        errorMap: () => ({ message: 'Dit veld mag niet leeg zijn.' }),
      })
      .min(8, { message: 'Het wachtwoord moet minimaal 8 tekens lang zijn.' })
      .regex(passwordRegex, {
        message:
          'Het wachtwoord moet ten minste één hoofdletter, één kleine letter, één cijfer en één speciaal teken bevatten.',
      }),
    repeatPassword: z.string({
      errorMap: () => ({ message: 'Dit veld mag niet leeg zijn.' }),
    }),
    email: z
      .string({
        errorMap: () => ({ message: 'Dit veld mag niet leeg zijn.' }),
      })
      .min(1, { message: 'Dit veld mag niet leeg zijn.' })
      .email({ message: 'Voer een geldig e-mailadres in.' }),
  })
  .refine((data) => data.password === data.repeatPassword, {
    message: 'De wachtwoorden komen niet overeen.',
    path: ['repeatPassword'],
  });

export type RegisterForm = z.infer<typeof registerFormSchema>;

export const contactFormSchema = z.object({
  validatedCaptcha: isCaptcha,
  firstName: notEmptyString,
  lastName: notEmptyString,
  email: z
    .string({
      errorMap: () => ({ message: 'Dit veld mag niet leeg zijn.' }),
    })
    .min(1, { message: 'Dit veld mag niet leeg zijn.' })
    .email('Vul een geldig e-mailadres in.'),
  phoneNumber: notEmptyString.regex(
    /^((\+31|0|[0-9]{3})[\s-]?[1-9][\s-]?[0-9]?(\d[\s-]?){5,6}\d)$/,
    {
      message:
        'Ongeldig telefoonnummer; vul in als +31 6 12345678, 06 12345678 voor mobiel of +31 20 1234567, 020 1234567 voor vast',
    }
  ),
  message: notEmptyString,
  streetName: optionalString,
  streetNumber: optionalString,
  postalCode: optionalString,
  city: optionalString,
});

export type ContactForm = z.infer<typeof contactFormSchema>;

export const forgotPasswordFormSchema = z
  .object({
    validatedCaptcha: isCaptcha,
    email: z
      .string({
        errorMap: () => ({ message: 'Dit veld mag niet leeg zijn.' }),
      })
      .min(1, { message: 'Dit veld mag niet leeg zijn.' })
      .email('Vul een geldig e-mailadres in.'),
  })
  .refine(validateUserExists, {
    message: 'Geen account gevonden voor het opgegeven e-mailadres.',
    path: ['email'],
  });

export type ForgotPasswordForm = z.infer<typeof forgotPasswordFormSchema>;

export const resetPasswordFormSchema = z
  .object({
    token: notEmptyString,
    verificationCode: notEmptyString,
    validatedCaptcha: isCaptcha,
    password: z
      .string({
        errorMap: () => ({ message: 'Dit veld mag niet leeg zijn.' }),
      })
      .min(8, { message: 'Het wachtwoord moet minimaal 8 tekens lang zijn.' })
      .regex(passwordRegex, {
        message:
          'Het wachtwoord moet ten minste één hoofdletter, één kleine letter, één cijfer en één speciaal teken bevatten.',
      }),
    repeatPassword: z.string({
      errorMap: () => ({ message: 'Dit veld mag niet leeg zijn.' }),
    }),
  })
  .refine((data) => data.password === data.repeatPassword, {
    message: 'De wachtwoorden komen niet overeen.',
    path: ['repeatPassword'],
  });

export type ResetPasswordForm = z.infer<typeof resetPasswordFormSchema>;

export const resetCurrentPasswordFormSchema = z
  .object({
    currentPassword: notEmptyString,
    password: z
      .string({
        errorMap: () => ({ message: 'Dit veld mag niet leeg zijn.' }),
      })
      .min(8, { message: 'Het wachtwoord moet minimaal 8 tekens lang zijn.' })
      .regex(passwordRegex, {
        message:
          'Het wachtwoord moet ten minste één hoofdletter, één kleine letter, één cijfer en één speciaal teken bevatten.',
      }),
    repeatPassword: z.string({
      errorMap: () => ({ message: 'Dit veld mag niet leeg zijn.' }),
    }),
  })
  .refine((data) => data.password === data.repeatPassword, {
    message: 'De nieuwe wachtwoorden komen niet overeen.',
    path: ['repeatPassword'],
  });

export type ResetCurrentPasswordForm = z.infer<typeof resetCurrentPasswordFormSchema>;

export const openPaymentSchema = z.object({
  amount: notEmptyString,
  debtorNumber: notEmptyString,
  invoiceNumber: notEmptyString,
  validatedCaptcha: isCaptcha,
  email: z
    .string({
      errorMap: () => ({ message: 'Dit veld mag niet leeg zijn.' }),
    })
    .min(1, { message: 'Dit veld mag niet leeg zijn.' })
    .email(),
});
export type OpenPayment = z.infer<typeof openPaymentSchema>;

export const createAccountFormSchema = z.object({
  email: notEmptyString,
  password: notEmptyString,
});

export const UserFormSchema = z
  .object({
    debtorNumber: optionalString,
    email: notEmptyString.email({ message: 'Vul een geldig e-mail in' }),
    id: optionalString,
  })
  .superRefine(async ({ id, email }, ctx) => {
    if (id && (await validateUpdateUserEmail({ email, uuid: id }))) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: EMAIL_TAKEN_MESSAGE,
        path: ['email'],
      });
    }
  });

export const productsFormSchema = z.object({
  id: z.number(),
  code: z.string().uuid(),
  title: z.string(),
  thumbnailImage: z.string().url(),
  unitPrice: z.number(),
  taxRate: z.number(),
  dishwash_factor: z.number(),
  in_shop: z.boolean(),
  slug: z.string().transform((val) => val.toLowerCase().replace(' ', '-')),
});

export type ProductsForm = z.infer<typeof productsFormSchema>;

export const ShopMetaImageSchema = z.object({
  url: z.string(),
  type: z.string(),
});

export const ShopMetaSchema = z.object({
  shop_description_short: z.string().nullable(),
  shop_description_long: z.string().nullable(),
  seo_title: z.string().nullable(),
  seo_description: z.string().nullable(),
  image_id: z.number().nullable(),
  images: z.array(ShopMetaImageSchema).nullable(),
  image_url: z.string().url().nullable(),
});

export const SpecificationsSchema = z.object({
  price: z.number().nullable(),
  tax_class_id: z.number().nullable(),
  tax_rate: z
    .union([z.literal(21), z.literal(9), z.literal(6)])
    .nullable()
    .optional(),
  length: z.number().nullable(),
  width: z.number().nullable(),
  height: z.number().nullable(),
});

export const GatewayProductSchema = z.object({
  id: z.number(),
  category_id: z.number().nullable(),
  code: z.string(),
  title: z.string(),
  step: z.number().optional().nullable(),
  minQuantity: z.number().optional().nullable(),
  setQuantity: z.number().optional().nullable(),
  slug: z.string().transform((val) => val.toLowerCase().replace(' ', '-')),
  shop_meta: ShopMetaSchema.nullable(),
  specifications: SpecificationsSchema.nullable(),
  dishwash_factor: z.number(),
  in_shop: z.boolean(),
});

export type GatewayProductForm = z.infer<typeof GatewayProductSchema>;

export const GatewayCategorySchema = z.object({
  id: z.number(),
  display_name: z.string(),
  name: z.string(),
  rentman_id: z.string(),
  parent_rentman_id: z.string().nullable(),
  order: z.number(),
  item_type: z.string(),
  path: z.string(),
});

export type GatewayCategoryForm = z.infer<typeof GatewayCategorySchema>;

export const GatewayInvoiceSchema = z.object({
  uuid: notEmptyString,
  amount: notEmptyNumber,
  open_amount: notEmptyNumber,
  reference: notEmptyString,
  status: notEmptyString,
  document_uuid: optionalString,
  document_id: optionalString,
  date: notEmptyString,
  invoice_number: notEmptyString,
});

export type GatewayInvoiceForm = z.infer<typeof GatewayInvoiceSchema>;

export const CartItemFormSchema = z.object({
  productId: notEmptyNumber,
  quantity: notEmptyNumber.min(1),
});

export type CartItemForm = z.infer<typeof CartItemFormSchema>;

export const postalCodeSchema = notEmptyString.regex(/^\d{4}\s?[A-Z]{2}$/i, {
  message: 'Verkeerde postcode; graag invullen als 1234AA of 1234 AA',
});

export const AddressFormSchema = z.object({
  firstName: notEmptyString,
  lastName: notEmptyString,
  middleName: optionalString,
  gender: notEmptyString,
  email: notEmptyString.email({ message: 'Vul een geldig e-mail in' }),
  streetName: notEmptyString,
  streetNumber: notEmptyNumber.min(1, 'Dit veld moet hoger dan 0 zijn'),
  streetNumberAdditional: optionalString,
  postalCode: postalCodeSchema,
  city: notEmptyString,
  phoneNumber: notEmptyString.regex(
    /^((\+31|0|[0-9]{3})[\s-]?[1-9][\s-]?[0-9]?(\d[\s-]?){5,6}\d)$/,
    {
      message:
        'Ongeldig telefoonnummer; vul in als +31 6 12345678, 06 12345678 voor mobiel of +31 20 1234567, 020 1234567 voor vast',
    }
  ),
  country: optionalString.transform((country) => country || 'nl'),
  kvk: optionalString,
  company: optionalString,
  label: optionalString,
});

export type AddressForm = z.infer<typeof AddressFormSchema>;

export const transportCostSchema = z.object({
  postalCode: postalCodeSchema,
});

export type TransportCostCalculatorValues = z.infer<typeof transportCostSchema>;

export const DefaultAddressFormSchema = z.object({
  id: notEmptyNumber,
  type: notEmptyString,
});

export const InvoiceAddressFormSchema = z.object({
  invoiceAddress: AddressFormSchema,
});

export type InvoiceAddressForm = z.infer<typeof InvoiceAddressFormSchema>;

export const ShippingAddressFormSchema = z.object({
  shippingAddress: AddressFormSchema,
  isDefault: z.boolean(),
});

export type ShippingAddressForm = z.infer<typeof ShippingAddressFormSchema>;

export const OrderSchema = z.object({
  reference: optionalString,
  delivery: optionalString,
  deliveryType: optionalString,
  invoiceAddress: z.any(),
  shippingAddress: z.any(),
  comment: optionalString,
  locationContact: optionalString,
  phoneNumberContact: optionalString,
  deliveryDate: notEmptyDate.refine(
    (date) =>
      !(
        new Date().getHours() >= 12 &&
        new Date(date).setHours(0, 0, 0, 0) ===
          new Date(Date.now() + DAY_IN_MS).setHours(0, 0, 0, 0)
      ),
    { message: DELIVERY_DATE_PAST12_MESSAGE }
  ),
  returnDate: notEmptyDate,
  payment: optionalString,
  dishwashing: optionalString,
  withGateway: z.boolean().optional(),
  deviatingInvoiceAddress: z.boolean().optional(),
});

const PickupOrderSchema = OrderSchema.merge(
  z.object({
    deliveryType: z.literal('pickup'),
    invoiceAddress: AddressFormSchema,
  })
);

const DeliveryOrderSchema = OrderSchema.merge(
  z.object({
    deliveryType: z.literal('delivery'),
    shippingAddress: z.union([z.number(), AddressFormSchema]),
  })
);

const DeliveryWithInvoiceOrderSchema = OrderSchema.merge(
  z.object({
    deliveryType: z.literal('deliveryWithInvoice'),
    shippingAddress: z.union([z.number(), AddressFormSchema]),
    invoiceAddress: AddressFormSchema,
  })
);

export const OrderFormSchema = z.discriminatedUnion('deliveryType', [
  PickupOrderSchema,
  DeliveryOrderSchema,
  DeliveryWithInvoiceOrderSchema,
]);

export type OrderForm = z.infer<typeof OrderFormSchema>;

const CustomDeliveryAddressSchema = z.object({
  country_code: notEmptyString,
  postal_code: notEmptyString,
  street: notEmptyString,
  house_number: notEmptyNumber,
  house_number_addition: optionalString,
  city: notEmptyString,
});

const PaymentSchema = z.object({
  is_paid: z.boolean(),
  dishwashing_fees: optionalNumber,
  product_total: notEmptyNumber,
  transport_fees: notEmptyNumber,
  total_amount_without_vat: notEmptyNumber,
  vat_high: notEmptyNumber,
  vat_low: notEmptyNumber,
  total_amount_with_vat: notEmptyNumber,
});

const ArticleSchema = z.object({
  code: notEmptyString,
  quantity: notEmptyNumber.min(1),
  tier_of_pricing: optionalNumber,
  unit_price: notEmptyNumber,
});

const InvoiceDetailsSchema = z.object({
  email: notEmptyString.email(),
  company_name: optionalString,
  kvk_number: optionalNumber,
  title: z.enum(['afdeling', 'man', 'vrouw']).optional(),
  first_name: notEmptyString,
  last_name_prefix: optionalString,
  last_name: notEmptyString,
  phone_number: notEmptyString,
  address: CustomDeliveryAddressSchema,
});

const OrderDetailsSchema = z.object({
  order_number: notEmptyString,
  is_delivery: z.boolean(),
  start_date: notEmptyString,
  end_date: notEmptyString,
  location_contact_person: optionalString,
  location_contact_person_phone: optionalString,
  note: optionalString,
  invoice_reference: optionalString,
  custom_delivery_title: z.enum(['afdeling', 'man', 'vrouw']).optional(),
  custom_delivery_first_name: optionalString,
  custom_delivery_last_name_prefix: optionalString,
  custom_delivery_last_name: optionalString,
  custom_delivery_phone_number: optionalString,
  custom_delivery_address: CustomDeliveryAddressSchema.optional(),
});

export const GatewayOrderFormSchema = z.object({
  invoice_details: InvoiceDetailsSchema,
  order_details: OrderDetailsSchema,
  payment: PaymentSchema,
  articles: z.array(ArticleSchema),
});
export type GatewayOrderForm = z.infer<typeof GatewayOrderFormSchema>;

export const SeoFormSchema = z.object({
  title: optionalString,
  description: optionalString,
  ogTitle: optionalString,
  ogDescription: optionalString,
});

export const PageFormSchema = z.object({
  slug: notEmptySlug,
  title: notEmptyString,
  concept: z.boolean().optional(),
  seo: SeoFormSchema.optional(),
});

export const NewsFormSchema = z.object({
  slug: notEmptySlug,
  title: notEmptyString,
  body: notEmptyString,
  date: notEmptyDate,
  concept: z.boolean().optional(),
  seo: SeoFormSchema.optional(),
});

export const mediaFormSchema = z.object({
  width: notEmptyNumber,
  height: notEmptyNumber,
  filename: notEmptyString,
  size: notEmptyNumber,
  mimetype: notEmptyString,
  filepath: notEmptyString,
  url: notEmptyString,
});

export const KeywordCityFormSchema = z.object({
  slug: notEmptySlug,
  title: notEmptyString,
  concept: z.boolean().optional(),
  seo: SeoFormSchema.optional(),
});

export const KeywordPageFormSchema = z.object({
  slug: notEmptySlug,
  title: notEmptyString,
  body: notEmptyString,
  keywordBody: notEmptyString,
  concept: z.boolean().optional(),
  seo: SeoFormSchema.optional(),
  cities: z.array(z.number()),
});

export const BlockedDateFormSchema = z.object({
  title: notEmptyString,
  date: notEmptyDate,
  pickup: z.boolean(),
  returnPickup: z.boolean(),
  delivery: z.boolean(),
  returnDelivery: z.boolean(),
});

export const SpecificationValueFormSchema = z.object({
  id: optionalNumber.or(optionalString),
  value: notEmptyString,
  displayOrder: optionalNumber,
  isDefault: z.boolean().optional(),
});

export const SpecificationFormSchema = z
  .object({
    id: optionalNumber,
    title: notEmptyString,
    type: notEmptyString,
    isRequired: z.boolean().optional(),
    values: z.array(SpecificationValueFormSchema).optional(),
  })
  .superRefine(async (val, ctx) => {
    if (val.type === 'select' && !val.values?.length) {
      ctx.addIssue({
        code: z.ZodIssueCode.too_small,
        minimum: 0,
        type: 'number',
        inclusive: true,
        message: 'Bij een lijst moet er minstens een optie opgegeven worden.',
        path: ['values'],
      });
    }
  });

export const CategorySpecificationFormSchema = z.object({
  specificationId: optionalNumber,
  isFilter: z.boolean(),
});

export const CategoryFormSchema = z
  .object({
    id: optionalNumber,
    shortDescription: optionalString,
    longDescription: optionalString,
    specifications: z.array(CategorySpecificationFormSchema),
    seo: SeoFormSchema.optional(),
  })
  .superRefine(async (val, ctx) => {
    if (val.specifications?.length && val.specifications.some((s) => !s.specificationId)) {
      ctx.addIssue({
        code: z.ZodIssueCode.too_small,
        minimum: 0,
        type: 'number',
        inclusive: true,
        message: 'Alle specificaties moeten een waarde bevatten.',
        path: ['specifications'],
      });
    }
  });

export const ProductSpecificationFormSchema = z.object({
  specificationValueIds: z.array(optionalNumber),
  specificationId: notEmptyNumber,
  value: optionalString,
});

export const AccessoryVariantFormSchema = z.object({
  productId: notEmptyNumber,
  defaultQuantity: notEmptyNumber,
  isDefault: z.boolean().optional(),
  title: notEmptyString,
});

export const AccessoryFormSchema = z.object({
  variants: z
    .array(AccessoryVariantFormSchema)
    .min(1, { message: 'Vul voor iedere accessoire ten minste 1 variant toe' }),
  title: notEmptyString,
});

export const ProductFormSchema = z.object({
  minQuantity: optionalNumber,
  setQuantity: optionalNumber,
  step: optionalNumber,
  specifications: z.array(ProductSpecificationFormSchema),
  accessories: z.array(AccessoryFormSchema),
});

export type ProductSpecificationForm = z.infer<typeof ProductSpecificationFormSchema>;

export const ProductStateFormSchema = z.object({
  productId: notEmptyNumber,
  quantity: notEmptyNumber,
});

export const AccessoryStateFormSchema = z.object({
  products: z.array(ProductStateFormSchema),
});

export const AccessoriesFormSchema = z.object({
  accessories: z.array(AccessoryStateFormSchema),
});

export type AccessoriesForm = z.infer<typeof AccessoriesFormSchema>;

export const TransportCostFormSchema = z
  .object({
    id: z.number().optional(),
    cost: notEmptyNumber,
    from: notEmptyNumber
      .min(1000, { message: 'Dit veld mag niet lager dan 1000 zijn.' })
      .max(9999, { message: 'Dit veld mag niet hoger dan 9999 zijn.' }),
    until: notEmptyNumber
      .min(1000, { message: 'Dit veld mag niet lager dan 1000 zijn.' })
      .max(9999, { message: 'Dit veld mag niet hoger dan 9999 zijn.' }),
  })
  .superRefine(async (val, ctx) => {
    if (val.until < val.from) {
      ctx.addIssue({
        code: z.ZodIssueCode.too_small,
        minimum: val.from,
        type: 'number',
        inclusive: true,
        message: 'Dit veld moet hoger zijn dan de vanaf postcode',
        path: ['until'],
      });
    }
    if (await validatePostalCodeRange({ id: val.id, postalCode: val.from })) {
      ctx.addIssue({
        code: z.ZodIssueCode.too_small,
        minimum: val.from,
        type: 'number',
        inclusive: true,
        message: 'Dit veld bestaat al in een bestaande postcoderange',
        path: ['from'],
      });
    }
    if (await validatePostalCodeRange({ id: val.id, postalCode: val.until })) {
      ctx.addIssue({
        code: z.ZodIssueCode.too_small,
        minimum: val.until,
        type: 'number',
        inclusive: true,
        message: 'Dit veld bestaat al in een bestaande postcoderange',
        path: ['until'],
      });
    }
  });

export const TierOfPricingFormSchema = z
  .object({
    id: z.number().optional(),
    days: notEmptyNumber,
    factor: notEmptyNumber,
  })
  .superRefine(async (val, ctx) => {
    if (await validateTierOfPricingDaysExists(val)) {
      ctx.addIssue({
        code: z.ZodIssueCode.too_small,
        minimum: val.days,
        type: 'number',
        inclusive: true,
        message: 'Dit aantal dagen bestaat al.',
        path: ['days'],
      });
    }
  });

export const SpecialTierOfPricingFormSchema = z.object({
  id: z.number().optional(),
  title: notEmptyString,
  fromDate: notEmptyDate,
  untilDate: notEmptyDate,
  factor: notEmptyNumber,
});

export const OrderListFormSchema = z.object({
  title: notEmptyString,
});

export type OrderListForm = z.infer<typeof OrderListFormSchema>;

export const OrderListItemFormSchema = z.object({
  orderListId: z.number().optional(),
  productId: notEmptyNumber,
  quantity: z.number().min(1, { message: 'Order list item quantity must be at least 1' }),
});
